button{
    padding: 0px;
    width: 200px;
    height: 50px;
}

.range{
    background-color: black;
    color: white;
}

.easy{
    background-color: green;
}
