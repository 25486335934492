.layout{
    display: grid;
    grid-template-areas: "easy medium hard";
    margin: 1%;
}

.easyRangeFiled{
    display: flex;
    grid-area: easy;
    height: fit-content;
    align-self: center;
    align-items: center;
    flex-direction: column;
}

.mediumRangeFiled{
    display: flex;
    grid-area: medium;
    height: fit-content;
    align-self: center;
    align-items: center;
    flex-direction: column;
}

.hardRangeFiled{
    display: flex;
    grid-area: hard;
    height: fit-content;
    align-self: center;
    align-items: center;
    flex-direction: column;
}